import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { data_download_api_resource } from "../../utils/http_functions";
import { dayjs } from "../../config";

import {
  Button, CircularProgress, MenuItem,
  Select, FormControl, InputLabel, Grid
} from "@material-ui/core";
import { GetApp, Search } from "@material-ui/icons"

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import saveAs from 'file-saver';
import DayJsUtils from '@date-io/dayjs';

const DateSelector = ({ onDatesChange, contractId, defaults, loading, endpointVersion }) => {
  const { i18n, t } = useTranslation();
  const token = useSelector((state) => state.auth.token);

  const [type, setType] = useState(defaults.type);
  const [startDate, setStartDate] = useState(defaults.startDate);
  const [endDate, setEndDate] = useState(defaults.endDate);
  const [downloading, setDownloading] = useState(false);
  const [downloadAvailable, setDownloadAvailable] = useState(false);

  const langDateFormat = { 'es': "DD/MM/YYYY", 'ca': "DD/MM/YYYY", 'en': "MM/DD/YYYY" }

  const handleChangeType = (event) => {
    setType(event.target.value);

    if(event.target.value === 'monthly') {
      handleFetchDates('monthly');
    }

    setDownloadAvailable(false);
  };

  const handleStartChange = (date) => {
    setStartDate(date);
  };

  const handleEndChange = (date) => {
    setEndDate(date);
  };

  const handleFetchDates = (newType = type) => {
    onDatesChange({type: newType, startDate, endDate});
    setDownloadAvailable(true);
  }

  const downloadCCH = () => {
    setDownloading(true);
    const url = `contracts/${contractId}/cch/download/?start=${startDate.toISOString()}&end=${endDate.toISOString()}`;
    data_download_api_resource(token, url, endpointVersion)
      .then(response => {
        const filename = response.headers["content-disposition"].split("=");
        saveAs(response.data, filename[1]);
      })
      .finally(() => {
        setDownloading(false);
      })
  };

  return (
    <FormControl>
      <Grid container alignItems="center" spacing={2} direction="row">

        <Grid item>
          <InputLabel>{t('common:text.cch_visualization')}</InputLabel>
          <Select onChange={handleChangeType} value={type}>
            <MenuItem key="monthly" value="monthly">{t('common:text.cch_monthly')}</MenuItem>
            <MenuItem key="daily" value="daily">{t('common:text.cch_daily')}</MenuItem>
            <MenuItem key="hourly" value="hourly">{t('common:text.cch_hourly')}</MenuItem>
          </Select>
        </Grid>

        {type !== 'monthly' &&
          <MuiPickersUtilsProvider utils={DayJsUtils} locale={i18n.language}>

            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                error={startDate == null || startDate == "Invalid Date"}
                helperText={startDate == null || startDate == "Invalid Date" ? t('common:text.cch_invalid_date') : ""}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                label={t("common:text.cch_start")}
                maxDate={endDate}
                format={langDateFormat[i18n.language]}
                onChange={handleStartChange}
                value={startDate}
                disabled={loading}
                variant="inline"
              />
            </Grid>

            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                error={endDate == null || endDate == "Invalid Date"}
                helperText={endDate == null || endDate == "Invalid Date" ? t('common:text.cch_invalid_date') : ""}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                label={t("common:text.cch_end")}
                minDate={startDate}
                format={langDateFormat[i18n.language]}
                onChange={handleEndChange}
                value={endDate}
                disabled={loading}
                variant="inline"
              />
            </Grid>

          </MuiPickersUtilsProvider>
        }

        {type === 'hourly' &&
          <Grid item>
            <Button
              variant={'contained'}
              onClick={downloadCCH}
              disabled={!downloadAvailable || downloading || loading}
              startIcon={downloading ? <CircularProgress size={20} thickness={3} /> : <GetApp />}
            >
              {t('common:text.contract_cch_download')}
            </Button>
          </Grid>
        }
        {type !== 'monthly' &&
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => handleFetchDates()}
              disabled={loading}
              startIcon={<Search />}
            >
              {t('common:text.contract_cch_search')}
            </Button>
          </Grid>
        }
      </Grid>
    </FormControl>
  )
}

export default DateSelector;
